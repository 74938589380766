export default [
  {
    title:"云游戏",
    path: "/",
    name: "Home",
    component: () => import("@/view/cloud/home.vue"),
  },
  {
    title:"云游戏",
    path: "/index",
    name: "Index",
    component: () => import("@/view/cloud/home.vue"),
  },
  {
    title:"云游戏",
    path: "/mobile",
    name: "Mobile",
    component: () => import("@/view/cloud/mobile.vue"),
  },
  {
    title:"云游戏-游戏窗口",
    path: "/cloud",
    name: "Cloud",
    component: () => import("@/view/cloud/game.vue"),
  },
  {
    title:"手机云游戏-游戏窗口",
    path: "/cloud/mobilegame",
    name: "Mobilegame",
    component: () => import("@/view/cloud/mobilegame.vue"),
  },
  {
    title:"新年集福",
    path: "/setf",
    name: "Setf",
    component: () => import("@/view/setf/index.vue"),
  },
  {
    title:"寻宝乐翻天",
    path: "/treasure",
    name: "Treasure",
    component: () => import("@/view/treasure/index.vue"),
  },
  {
    title:"CDK回收",
    path: "/recy",
    name: "Recy",
    component: () => import("@/view/recy/index.vue"),
  },
  {
    title:"成长大挑战",
    path: "/growth",
    name: "Growth",
    component: () => import("@/view/growth/index.vue"),
  },
  {
    title:"欢乐夺宝",
    path: "/indiana",
    name: "Indiana",
    component: () => import("@/view/indiana/index.vue"),
  },
  {
    title:"阿拉德战令",
    path: "/makes",
    name: "Makes",
    component: () => import("@/view/makes/index.vue"),
  },
  {
    title:"马战大赛(马战)",
    path: "/zhan",
    name: "Zhan",
    component: () => import("@/view/zhan/index.vue"),
  },
  {
    title:"签到",
    path: "/sign",
    name: "Sign",
    component: () => import("@/view/sign/index.vue"),
  },
  {
    title:"机智的畅玩探索",
    path: "/wit",
    name: "Wit",
    component: () => import("@/view/wit/index.vue"),
  },
   {
    title:"排行榜",
    path: "/ranking",
    name: "Ranking",
    component: () => import("@/view/ranking/index.vue"),
  },
  {
    title:"注册游戏",
    path: "/r",
    name: "R",
    component: () => import("@/view/regist/index.vue"),
  },
  {
    title:"游戏官网",
    path: "/w",
    name: "W",
    component: () => import("@/view/website/index.vue"),
  },
  {
    title:"直充内置",
    path: "/g",
    name: "G",
    component: () => import("@/view/gateway/index.vue"),
  },
  {
    title:"商品内置",
    path: "/goods",
    name: "Goods",
    component: () => import("@/view/goods/index.vue"),
  },
  {
    title:"内置Gm工具",
    path: "/gm",
    name: "Gm",
    component: () => import("@/view/gm/index.vue"),
  },
  {
    title:"飞升版-转生系统",
    path: "/msh",
    name: "Msh",
    component: () => import("@/view/msh/index.vue"),
  },
  {
    title:"集市拍卖",
    path: "/tian",
    name: "Tian",
    component: () => import("@/view/tian/index.vue"),
  },
  {
    title:"启动！创世纪号动力引擎",
    path: "/engine",
    name: "Engine",
    component: () => import("@/view/engine/index.vue"),
  },
  {
    title:"20人团本攻坚战",
    path: "/group",
    name: "Eroup",
    component: () => import("@/view/group/index.vue"),
  },
  {
    title:"月签到",
    path: "/monthsign",
    name: "Monthsign",
    component: () => import("@/view/monthsign/index.vue"),
  },
  {
    title:"夏梦冰乐园大舞台",
    path: "/stage",
    name: "Stage",
    component: () => import("@/view/stage/index.vue"),
  },
  {
    title:"与希曼的夏日回忆",
    path: "/seaman",
    name: "Seaman",
    component: () => import("@/view/seaman/index.vue"),
  },
  {
    title:"土罐的经典袖珍罐",
    path: "/earthen",
    name: "Earthen",
    component: () => import("@/view/earthen/index.vue"),
  },
];


