import Vue from "vue";
import router from "./router";
import App from "./App.vue";
import "../public/umi.css";
import "../public/layouts__index.css";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import global from './config/socket.js'
import config from './config/config.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/ali-img/iconfont.css'
//import { addListener, launch } from 'devtools-detector';

Vue.prototype.global = global
Vue.prototype.config = config

//监控是否打开控制台
// addListener(
//     isOpen => (isOpen ? location.href = 'https://www.baidu.com' : '')
// )
// launch();


router.beforeEach((to, from, next) => {
  console.log(to.path);
  console.log( from.path);
  if (to.path !== from.path) {
    if(from.path != "/"){
      location.reload()
    }
   
  }
  next()
})

// //Vue.prototype.axios = axios
Vue.use(VueAxios, axios)


Vue.use(ElementUI)

Vue.config.productionTip = false;

new Vue({
  router,
  axios,
  render: (h) => h(App),
}).$mount("#app");
